import React from 'react'
import axios from 'axios';

export const useAxios = (overrideApiUrl = null) => {
    axios.defaults.baseURL = overrideApiUrl ?  `${process.env.NEXT_PUBLIC_BACKEND}` : `${process.env.NEXT_PUBLIC_BACKEND}/api`;
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios.defaults.headers.common['Accept'] = 'application/json';
   // axios.defaults.params = {};
   // axios.defaults.params['v'] = process.env.NEXT_PUBLIC_VERSIE;
    axios.interceptors.response.use(undefined, function (error) {
        if (error?.response?.status === 422) {
            let meldingen = [];
            for (const [keyVeldnaam, valArr] of Object.entries(error.response.data.errors)) {
                meldingen = meldingen.concat(valArr);
            }
            error.inputMessage = meldingen;
        }

        return Promise.reject(error);
    })


    const  requestMetCRSF = async (callback, url, data) => {


        if (document?.cookie.match(/^(.*;)?\s*XSRF-TOKEN\s*=\s*[^;]+(.*)?$/) === null) {
           await axios.get(`${process.env.NEXT_PUBLIC_BACKEND}/sanctum/csrf-cookie`);
           return callback(url, data).finally(() => {

            })

        } else {
            return callback(url, data).finally(() => {

            })
        }
    }

    const all = (axiosCalls) =>{
        return axios.all(axiosCalls)
    }


    const get = (url, params) => {
        return axios.get(url, params).finally(() => {

        })
    }

    const post = (url, data) => {
        const postfun = (url, data) => { return axios.post(url, data)}
        return requestMetCRSF(postfun, url, data )
    }

    const put = (url, data) => {
        const putfun = (url, data) => { return axios.put(url, data)}
        return requestMetCRSF(putfun, url, data)
    }

    const del = (url, data) => {
        const delfun = (url, data) => { return axios.delete(url, data)}
        return requestMetCRSF(delfun , url, data)
    }



    return {get, post, put, del, all}
}