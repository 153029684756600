"use client"
import useSWR from "swr";
import {useAxios} from "./useAxios";


const fetchGebruiker = (url) => {
    return useAxios().get(url)
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status !== 409) throw error;
        });
};
const fetchNamensbedrijf = (url) => {
    return useAxios().get(url)
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status !== 409) throw error;
        });
};
const fetchRechten = (url) => {
    return useAxios().get(url)
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status !== 409) throw error;
        });
};


export default function useGebruiker() {
    const { data: ingelogdeGebruikerData, error: errorGebruiker, isLoading: ingelogdeGebruikerIsLoading } = useSWR(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik`, fetchGebruiker, {
        refreshInterval: 0,
        revalidateIfStale: false,
        revalidateOnReconnect: true,
        revalidateOnFocus: false, }); // refreshInterval 5 min
    const { data: namensBvData, error: errorNamensBv, isLoading: namensBvIsLoading  } = useSWR(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf`, fetchNamensbedrijf, {
        refreshInterval: 0,
        revalidateIfStale: false,
        revalidateOnReconnect: true,
        revalidateOnFocus: false,  });
    const { data: rechtenData, error: rechtenError, isLoading: rechtenIsLoading  } = useSWR(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/rechten`, fetchRechten, {
        refreshInterval: 0,
        revalidateIfStale: false,
        revalidateOnReconnect: true,
        revalidateOnFocus: false,
         }); //refreshInterval: 300000, revalidateOnFocus: false,

    const isLoggedOut = errorGebruiker && errorGebruiker.status === 401;

    return {
        ingelogdeGebruikerIsLoading,
        isLoggedOut,
        ingelogdeGebruiker: ingelogdeGebruikerData?.data,
        namensBedrijf: namensBvData,
        namensBedrijfIsLoading: namensBvIsLoading,
        rechten: rechtenData,
        rechtenIsLoading: rechtenIsLoading
    };
}
