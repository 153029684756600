"use client"
import { MdMoreVert } from "react-icons/md";
import {useState} from "react";
import RechtermuisClickMenu from "hooks/rechtermuisclickmenu";

export const RightMouseClickMenu = ({id, weergaveId, bewerkMenuCurrent,bewerkMenuPrev, bewerkMenuNext}) =>{

    const [showMenu, setShowMenu] = useState(false)


    function handleShow(e){
        e.preventDefault()
        setShowMenu(!showMenu)
    }

    return <div className={`z-50 absolute right-2 bottom-2 cursor-pointer`}>
        {showMenu && <RechtermuisClickMenu
            weergaveId={weergaveId}
            show={showMenu}
            setShow={setShowMenu}
            contentItemId={id}
            volgordePrev={bewerkMenuPrev}
            volgordeCurrent={bewerkMenuCurrent}
            volgordeNext={bewerkMenuNext}
        />}
        <div className={`z-100 absolute right-2 bottom-2 cursor-pointer`}>
            <MdMoreVert title={`opties..`} onClick={handleShow} onContextMenu={handleShow} className={`text-kleur-cyan text-l`}/>
        </div>
    </div>

}