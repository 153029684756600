"use client"
import React from "react";
import {AiOutlineDownCircle, AiOutlineEdit, AiOutlinePlusCircle, AiOutlineUpCircle} from "react-icons/ai";
import {useAxios} from "./useAxios";
import revalidateAction from "../lib/actions";

const RechtermuisClickMenu = ({ setShow, contentItemId, weergaveId = null, volgordeCurrent = null, volgordePrev = null, volgordeNext = null}) => {

    const {post,put} = useAxios()


    const handleAddItem = () => {
        post('wiki', {wiki_vorm_id: 20, weergave: weergaveId }).then((result) => {
            window.open(`/mijn-mkg/beheer/content-management/content/${result.data.id}`, '_blank');
        })
        setShow(false)
    }

    const handleDuplicateItem = () => {
        post(`wiki/${contentItemId}/duplicate`).then((result) => {
            window.open(`/mijn-mkg/beheer/content-management/content/${result.data.id}`, '_blank');
        })
        setShow(false)
    }

    const handleAddItemBefore = () => {
        let volgorde;
        if (!Number.isInteger(volgordePrev)) {
            volgorde = volgordeCurrent - 1000000
        } else {
            volgorde = ((volgordeCurrent - volgordePrev) / 2) + volgordePrev
        }
        post('wiki', {wiki_vorm_id: 20, weergave: weergaveId, volgorde: volgorde }).then((result) => {
            window.open(`/mijn-mkg/beheer/content-management/content/${result.data.id}`, '_blank');
        })
        setShow(false)
    }
    const handleAddItemAfter = () => {
        let volgorde;
        if (!Number.isInteger(volgordeNext)) {
            volgorde = volgordeCurrent + 1000000
        } else {
            volgorde = ((volgordeNext - volgordeCurrent) / 2) + volgordeCurrent
        }
        post('wiki', {wiki_vorm_id: 20, weergave: weergaveId, volgorde: volgorde }).then((result) => {
            window.open(`/mijn-mkg/beheer/content-management/content/${result.data.id}`, '_blank');
        })
        setShow(false)
    }

    const handleVerplaatsOmhoog = () => {
        put(`weergave/${weergaveId}/verplaatsItemOmhoog` , {wiki_item_id: contentItemId }).then((result) => {
            revalidateAction('weergaves')
        })
        setShow(false)
    }
    const handleVerplaatsOmlaag = () => {
        put(`weergave/${weergaveId}/verplaatsItemOmlaag` , {wiki_item_id: contentItemId }).then((result) => {
            revalidateAction('weergaves')
        })
        setShow(false)
    }
    const handleBewerken = () => {
        window.open(`/mijn-mkg/beheer/content-management/content/${contentItemId}`, '_blank');
        setShow(false)
    }

    return  <div className={'dropdown-menu dropdown-menu-md show right-2 bottom-8 mb-2'}>
        {weergaveId && (volgordeCurrent || volgordeCurrent === 0 ? <>
                    <div className={'dropdown-item hover:bg-kleur-licht-grijs-blauw'} onClick={handleAddItemBefore}><AiOutlinePlusCircle className={`inline-block`}/> Item hierboven</div>
                    <div className={'dropdown-item hover:bg-kleur-licht-grijs-blauw'} onClick={handleAddItemAfter}><AiOutlinePlusCircle className={`inline-block`}/> item hieronder</div>
                    <hr/>
                    <div className={'dropdown-item hover:bg-kleur-licht-grijs-blauw' + (!Number.isInteger(volgordePrev) ? ' disabled' : '')} onClick={handleVerplaatsOmhoog} ><AiOutlineUpCircle className={`inline-block`}/> Verplaats omhoog</div>
                    <div className={'dropdown-item hover:bg-kleur-licht-grijs-blauw' + (!Number.isInteger(volgordeNext) ? ' disabled' : '')} onClick={handleVerplaatsOmlaag}><AiOutlineDownCircle className={`inline-block`} /> Verplaats omlaag</div>
                    <hr/>
                </>
                : <div className={'dropdown-item hover:bg-kleur-licht-grijs-blauw'} onClick={handleAddItem}><AiOutlinePlusCircle className={`inline-block`}/> Item</div>
        )}
        <div className={'dropdown-item hover:bg-kleur-licht-grijs-blauw'} onClick={handleBewerken}><AiOutlineEdit className={`inline-block`}/> Bewerken {contentItemId}</div>
        <div className={'dropdown-item hover:bg-kleur-licht-grijs-blauw'} onClick={handleDuplicateItem}><AiOutlineEdit className={`inline-block`}/> Dupliceer {contentItemId}</div>
    </div>
};
export default RechtermuisClickMenu;