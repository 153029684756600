'use client'
import React, {useState, useEffect} from "react";
import classes from "./Formulier.module.scss";
import {
    Button,
    FormControl as BootstrapFormControl,
    Alert
} from "react-bootstrap";
import {BsChevronCompactDown} from '@react-icons/all-files/bs/BsChevronCompactDown'
import {BsChevronCompactUp} from '@react-icons/all-files/bs/BsChevronCompactUp'
import {useTekstClient} from "hooks/useTekstClient";
import {useAxios} from "hooks/useAxios";


const Formulier = ({uitgebreid = false, titel = null, intro = null, outro = null}) => {

    const tekst = useTekstClient();
    const [naam, setNaam] = useState('')
    const [tel, setTel] = useState('')
    const [toelichting, setToelichting] = useState('')
    const [showToelichting, setShowToelichting] = useState(false)
    const [bedrijfsnaam, setBedrijfsnaam] = useState('')
    const [email, setEmail] = useState('')
    const [aantalGebruikers, setAantalgebruikers] = useState('')
    const [afgerond, setAfgerond] = useState(false)
    const [errorsItem, setErrorsItem] = useState();

    const [isClient, setIsClient] = useState(false)
    const {post, loading} = useAxios()

    useEffect(() => {
        setIsClient(true)
    }, [])


    const handleClickVerzenden = () => {
        const dataToSend = {
            formulier_naam: naam,
            formulier_tel: tel,
            formulier_toelichting: toelichting,
        }
        if (uitgebreid) {
            dataToSend.formulier_bedrijfsnaam = bedrijfsnaam;
            dataToSend.formulier_email = email;
            dataToSend.formulier_aantalgebruikers = aantalGebruikers;
        }
        post(`/formulier${uitgebreid ? '/uitgebreid' : ''}`, dataToSend).catch(function (error) {
            setErrorsItem(error.inputMessage || [tekst('algemeen:error')])
        })

    }

    return (<>
            {titel && <div id='invullen' className={`mkgeu_titel pt-5`}>{titel}</div>}
            {intro && <div className={`mkgeu_intro `} style={{maxWidth: '360px', margin: '0 auto'}}>{intro}</div>}

            <div className={classes.box}>
                {afgerond
                    ? <div style={{textAlign: 'center'}}>{tekst('mkgeu:formulier:succesvol')}</div>
                    :
                    <>
                        <div className={classes.boxForm}>
                            {uitgebreid
                                ? <>
                                    <div className={classes.boxFormInner}>
                                        <BootstrapFormControl type="input"
                                                              placeholder={tekst('mkgeu:formulier:bedrijfnaam')}
                                                              onChange={(e) => setBedrijfsnaam(e.target.value)}
                                                              value={bedrijfsnaam}
                                        />
                                        <BootstrapFormControl type="input"
                                                              placeholder={tekst('mkgeu:formulier:naam')}
                                                              onChange={(e) => setNaam(e.target.value)}
                                                              value={naam}
                                        />
                                        <BootstrapFormControl type="email"
                                                              placeholder={tekst('mkgeu:formulier:email')}
                                                              onChange={(e) => setEmail(e.target.value)}
                                                              value={email}
                                        />
                                        <BootstrapFormControl type="tel"
                                                              placeholder={tekst('mkgeu:formulier:telefoonnr')}
                                                              onChange={(e) => setTel(e.target.value)}
                                                              value={tel}
                                        />

                                    </div>
                                    <div className={classes.boxFormInner}>
                                        <BootstrapFormControl type="number"
                                                              placeholder={tekst('mkgeu:formulier:aantalgebruikers')}
                                                              onChange={(e) => setAantalgebruikers(e.target.value)}
                                                              value={aantalGebruikers}
                                        />
                                        <BootstrapFormControl as="textarea" rows={3}
                                                              placeholder={tekst('mkgeu:formulier:opmerking')}
                                                              onChange={(e) => setToelichting(e.target.value)}
                                                              value={toelichting}
                                        />

                                        <Button disabled={(!naam || !tel)} variant="primary" size={'lg'}
                                                onClick={handleClickVerzenden}>
                                            {tekst('mkgeu:formulier:verzenden')}
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={classes.boxInputs}>
                                        <div className={classes.rowInputs}>
                                            <div style={{flexGrow: 4}}>
                                                <BootstrapFormControl type="input"
                                                                      placeholder={tekst('mkgeu:formulier:naam')}
                                                                      onChange={(e) => setNaam(e.target.value)}
                                                                      value={naam}
                                                />
                                            </div>
                                            <div style={{flexGrow: 4}}>
                                                <BootstrapFormControl type="input"
                                                                      placeholder={tekst('mkgeu:formulier:telefoonnr')}
                                                                      onChange={(e) => setTel(e.target.value)}
                                                                      value={tel}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.toelichtingBox}>
                                            {isClient &&
                                                <div className={classes.toelichtingLabel}
                                                     onClick={() => setShowToelichting(!showToelichting)}>
                                                    {tekst('mkgeu:formulier:eventuele toelichting')}
                                                    {showToelichting ? <BsChevronCompactUp/> :
                                                        <BsChevronCompactDown/>}
                                                </div>}
                                            <div
                                                className={`${classes.toelichting} ${showToelichting ? classes.open : ''}`}>
                                                <BootstrapFormControl as="textarea" rows={3}
                                                                      placeholder={tekst('mkgeu:formulier:toelichting')}
                                                                      onChange={(e) => setToelichting(e.target.value)}
                                                                      value={toelichting}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{flexShrink: 4}}
                                         className={`${classes.btnbox} ${showToelichting ? classes.open : ''}`}>
                                        {isClient &&
                                            <Button disabled={(!naam || !tel || loading)} variant="primary"
                                                    size={'lg'}
                                                    onClick={handleClickVerzenden}>
                                                {tekst('mkgeu:formulier:verzenden')}
                                            </Button>}
                                    </div>
                                </>
                            }
                        </div>
                        {errorsItem
                            && <Alert className="my-3" variant='danger' style={{width: '600px', margin: '0 auto'}}>
                                <ul>{errorsItem.map(x => <li key={x}>{x}</li>)}</ul>
                            </Alert>}

                    </>}
                {outro && <div className={`mkgeu_outro `}
                               style={{maxWidth: '360px', margin: '0 auto', textAlign: "center"}}
                               >{outro }</div> }
                <div className={classes.outroImages}>
                    <img loading={'lazy'} src='/images/formulier/eye-security.png' className='trust_logo'
                         alt='Eye Security'/>
                    <img loading={'lazy'} src='/images/formulier/metaalunie.png' className='trust_logo'
                         alt='Metaal Unie'/>
                    <img loading={'lazy'} src='/images/formulier/progress.png' className='trust_logo'
                         alt='Progress'/>
                    <img loading={'lazy'} src='/images/formulier/saxion.png' className='trust_logo' alt='Saxion'/>
                    <img loading={'lazy'} src='/images/formulier/smart-industry.png' className='trust_logo'
                         alt='Smart Industry'/>
                    <img loading={'lazy'} src='/images/formulier/twente-board.png' className='trust_logo'
                         alt='Twente Board'/>
                    <img loading={'lazy'} src='/images/formulier/university-of-twente.png' className='trust_logo'
                         alt='Univerity of Twente'/>
                    <img loading={'lazy'} src='/images/formulier/vmo.png' className='trust_logo'
                         alt='Verenigde Maakidustrie Oost'/>
                </div>
            </div>

        </>
    );

}
export default Formulier;